import { DETAIL_SCREEN_KBN, MSG_TYPE, ROW_KBN } from '@/common/const.js'
import {
  openMsgModal,
  formatYYYYMMDD,
  displaYyyymmdd,
} from '@/common/Common.js'
import SurchargeEditInfo from './SurchargeEditInfo'
import _ from 'lodash'

export function init(obj) {
  if (obj.getDatas.detailScreenKbn == DETAIL_SCREEN_KBN.NEW) {
    //追加
    obj.surchargeEdit = new SurchargeEditInfo()
  } else {
    //編集
    obj.surchargeEdit.vehicleCategory =
      obj.getDatas.selectedItem[0].vehicle_category
    obj.surchargeEdit.validBeginDate2 =
      obj.getDatas.selectedItem[0].valid_begin_date
    obj.surchargeEdit.adjustmentMoneyRate =
      obj.getDatas.selectedItem[0].adjustment_money_rate
    obj.surchargeEdit.freightItemId =
      obj.getDatas.selectedItem[0].freight_item_id
    obj.surchargeEdit.freightItemName =
      obj.getDatas.selectedItem[0].freight_item_name
    obj.surchargeEdit.branchNo = obj.getDatas.selectedItem[0].branch_no
    obj.surchargeEdit.adjustmentMoneyKind =
      obj.getDatas.selectedItem[0].adjustment_money_kind
  }
  // validate reset
  obj.$refs['tab-group'].$refs['base-tab-group'].reset()
}

export async function setting(obj) {
  const validated = await obj.$refs['tab-group'].$refs[
    'base-tab-group'
  ].validate()
  if (!validated) return

  // 編集の場合、日付変更できないため、チェックしない
  if (obj.getDatas.detailScreenKbn != DETAIL_SCREEN_KBN.DETAIL) {
    let dateA = obj.surchargeEdit.validBeginDate2

    //削除対象の輸送手段カテゴリ
    let addVehicleCategory = obj.surchargeEdit.vehicleCategory
    let addBranchNo = obj.surchargeEdit.branchNo
    let addAdjustmentMoneyKind = obj.surchargeEdit.adjustmentMoneyKind
    let lastIndex = findLastIndex(
      obj,
      addVehicleCategory,
      addBranchNo,
      addAdjustmentMoneyKind
    )
    dateA = formatYYYYMMDD(obj, dateA) //入力日付
    dateA = displaYyyymmdd(obj, dateA)

    //let dateB = obj.getDatas.prevValidBeginDate
    let dateB = null
    if (lastIndex != null) {
      //同一輸送手段カテゴリのMAX日付
      dateB = obj.getDatas.surchargeDtos[lastIndex].valid_begin_date
    }

    // // 入力した有効開始日付(A)が当日(B)より
    // //  未来日になっているかチェック
    // if (isAfterToday(obj, dateA) == false) {
    //   // 期間日付の入力に不整合があります。
    //   openMsgModal(
    //     obj,
    //     MSG_TYPE.W,
    //     obj.$store.getters['init/getMessage']('YZ00MG992E')
    //   )
    //   return
    // }

    // 入力した有効開始日付(A)が最新有効開始日付(B)より
    //  未来日になっているかチェック
    if (
      obj.getDatas.rowKbn != ROW_KBN.NONE &&
      isAfterAthanB(obj, dateA, dateB) == false
    ) {
      // 期間日付の入力に不整合があります。
      openMsgModal(
        obj,
        MSG_TYPE.W,
        obj.$store.getters['init/getMessage']('YZ00MG992E')
      )
      return
    }
  }

  obj.$bvModal.hide('surcharge-edit-modal')
  let newEdit = _.cloneDeep(obj.surchargeEdit)
  newEdit.validBeginDate2 = formatYYYYMMDD(obj, newEdit.validBeginDate2)
  obj.$emit('after-close-set', newEdit)
}
/**
 * dateAがdateBより未来日か
 * @param {*} obj
 * @param {*} dateA
 * @param {*} dateB
 */
function isAfterAthanB(obj, dateA, dateB) {
  let date1 = obj.$moment(new Date(dateA))
  let date2 = obj.$moment(new Date(dateB))
  let isAfter = date1.isAfter(date2)
  return isAfter
}

// /**
//  * 当日より未来日か
//  * @param {*} obj
//  * @param {*} dateA
//  */
// function isAfterToday(obj, dateA) {
//   let today = obj.$moment(new Date()).format('YYYYMMDD')
//   today = displaYyyymmdd(obj, today)
//   return isAfterAthanB(obj, dateA, today)
// }

export function findLastIndex(
  obj,
  inVehicleCategory,
  branchNo,
  adjustmentMoneyKind
) {
  let lastIndex = null
  for (let idx = 0; idx < obj.getDatas.surchargeDtos.length; idx++) {
    let currVehicleCategory = obj.getDatas.surchargeDtos[idx].vehicle_category
    let currBranchNo = obj.getDatas.surchargeDtos[idx].branch_no
    let currAdjustmentMoneyKind =
      obj.getDatas.surchargeDtos[idx].adjustment_money_kind
    if (
      inVehicleCategory == currVehicleCategory &&
      branchNo == currBranchNo &&
      adjustmentMoneyKind == currAdjustmentMoneyKind
    ) {
      lastIndex = idx
    }
  }
  return lastIndex
}
