/**
 * 燃料調整金編集データ.
 * @class
 * @exports
 */
export default class surchargeEdit {
  //--------------TAB1------------//
  vehicleCategory = null
  vehicleCategoryName = null
  validBeginDate2 = null
  adjustmentMoneyRate = null
  freightItemId = null
  freightItemName = null
  surchargeId
  branchNo = null
  adjustmentMoneyKind = null
  adjustmentMoneyKindName = null
}
