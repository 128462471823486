<template>
  <div>
    <BaseModal
      dataClass="definition-item-modify-modal"
      size="lg"
      v-bind="$attrs"
      v-on="$listeners"
    >
      <template v-slot:main>
        <div data-class="work-list" class="mx-2 my-2">
          <BaseTabGroup
            ref="tab-group"
            :inputModel="surchargeEdit"
            :inputComponents="inputComponents"
          >
            <template v-slot:margin>
              <div v-for="(n, i) in 14" v-bind:key="i + Math.random()">
                <br />
              </div>
            </template>
          </BaseTabGroup>
          <br />
        </div>
      </template>
      <template v-slot:footer="{ cancel }">
        <!-- 設定ボタン -->
        <BaseButton
          variant="primary"
          icon="check-circle"
          :columnInfo="resultControlInfo.setting"
          @click="setting(obj)"
        />
        <!-- 閉じるボタン -->
        <BaseButton
          variant="primary"
          icon="times-circle"
          :columnInfo="resultControlInfo.close"
          @click="cancel"
        />
      </template>
    </BaseModal>
    <!-- 運賃項目マスタモーダル -->
    <FreightItemModal
      id="freight-item-modal-edit"
      type="entry"
      displayModals="two"
      :selectedSearchCondition="freightItemModalSearchCondition"
      :fixedPayment="false"
      @after-close-set="afterCloseSetFreightItem"
      @after-close-unset="afterCloseUnsetFreightItem"
      @set-error-msg="setErrorMsg"
      @set-warn-msg="setWarnMsg"
    />
  </div>
</template>

<script>
import SurchargeEditInfo from './SurchargeEditInfo.js'
//import { COST_TYPE } from '@/common/const.js'
import { getListOptions, openSubModal } from '@/common/Common.js'
import {
  SCREEN_ID,
  DETAIL_SCREEN_KBN,
  VISIBILITY,
  SLOT_NAME,
} from '@/common/const.js'
import { init, setting } from '@/master/tariff/SurchargeEditModal.js'

const screenId = SCREEN_ID.SURCHARGE_EDIT_MODAL

export default {
  components: {
    FreightItemModal: () => import('@/master/freightItem/FreightItemModal.vue'),
  },

  props: {
    selectedItem: {
      type: Object,
    },
    getDatas: {
      type: Object,
      default: () => {},
    },
  },

  watch: {
    getDatas() {
      init(this)
    },
  },

  data() {
    return {
      surchargeEdit: new SurchargeEditInfo(),
      modalKbn: 0,
      obj: this,
      surchargeValidPeriodId: null,
      freightItemModalSearchCondition: {},
    }
  },

  computed: {
    inputComponents() {
      return [
        {
          // タブ
          label: this.detailInfo.tab1.label,
          slot: SLOT_NAME.MARGIN,
          inputComponents: [
            {
              // 輸送手段カテゴリ
              type: 'select',
              id: 'vehicleCategory',
              columnInfo: this.detailInfo.vehicle_category,
              options: getListOptions(this.MASTER_CODE.VEHICLE_CATEGORY),
              rules: [this.INPUT_TYPE_CHECK.REQUIRED],
              onChangeCallback: () => {},
            },
            {
              // 有効開始日付
              type: 'date',
              id: 'validBeginDate2',
              columnInfo: this.detailInfo.valid_begin_date2,
              rules: [this.INPUT_TYPE_CHECK.REQUIRED],
            },
            {
              // 燃料調整金設定率
              type: 'text',
              id: 'adjustmentMoneyRate',
              columnInfo: this.detailInfo.adjustment_money_rate,
              rules: [
                this.INPUT_TYPE_CHECK.REQUIRED,
                this.INPUT_TYPE_CHECK.FLOAT,
              ],
            },
            {
              // 運賃項目
              type: 'referenceCodeName',
              codeId: 'freightItemId',
              nameId: 'freightItemName',
              codeColumnInfo: this.detailInfo.freight_item_id,
              nameColumnInfo: this.detailInfo.freight_item_name,
              btnColumnInfo: this.detailInfo.sub_modal_freight_item,
              codeRules: [this.INPUT_TYPE_CHECK.REQUIRED],
              nameRules: [this.INPUT_TYPE_CHECK.REQUIRED],
              onClickCallback: () => {
                // 請求支払区分参照時、請求支払区分デフォルト設定
                this.freightItemModalSearchCondition = {}
                // 基本情報タブの請求支払区分
                this.freightItemModalSearchCondition.costType = this.getDatas.isPayment
                // 荷主の部署グループ
                //this.departmentGroup
                this.openSubModal(this, 'freight-item-modal-edit')
              },
            },
            {
              // 枝番
              type: 'text',
              id: 'branchNo',
              columnInfo: this.detailInfo.branch_no,
              minValue: 0,
              maxValue: 999,
              rules: [
                this.INPUT_TYPE_CHECK.REQUIRED,
                this.INPUT_TYPE_CHECK.MINVALUE,
                this.INPUT_TYPE_CHECK.MAXVALUE,
              ],
            },
            {
              // 調整金区分
              type: 'select',
              id: 'adjustmentMoneyKind',
              columnInfo: this.detailInfo.adjustment_money_kind,
              options: getListOptions(this.MASTER_CODE.ADJUSTMENT_MONEY_KIND),
              // rules: [this.INPUT_TYPE_CHECK.REQUIRED],
              onChangeCallback: () => {},
            },
          ],
        },
      ]
    },

    detailInfo() {
      let info = this.$store.getters['init/getScreens'](screenId)('detail_info')
      if (this.getDatas.detailScreenKbn == DETAIL_SCREEN_KBN.DETAIL) {
        info.valid_begin_date2.visibility = VISIBILITY.DISABLE
        info.vehicle_category.visibility = VISIBILITY.DISABLE
        info.adjustment_money_kind.visibility = VISIBILITY.DISABLE
        info.branch_no.visibility = VISIBILITY.DISABLE
      } else {
        info.valid_begin_date2.visibility = VISIBILITY.VISIBLE
        info.vehicle_category.visibility = VISIBILITY.VISIBLE
        info.adjustment_money_kind.visibility = VISIBILITY.VISIBLE
        info.branch_no.visibility = VISIBILITY.VISIBLE
      }
      return info
    },
    resultControlInfo() {
      return this.$store.getters['init/getScreens'](screenId)(
        'result_ctrl_info'
      )
    },
  },

  methods: {
    getListOptions,
    init,
    openSubModal,
    setting,

    /**
     * 運賃項目マスタから取得データ設定
     */
    afterCloseSetFreightItem(selectedItem) {
      this.surchargeEdit.freightItemId = selectedItem.freight_item_id
      this.surchargeEdit.freightItemName = selectedItem.freight_item_name
    },
    afterCloseUnsetFreightItem() {
      this.surchargeEdit.freightItemId = null
      this.surchargeEdit.freightItemName = null
    },

    setErrorMsg(msg) {
      this.$emit('set-error-msg', msg)
    },

    setWarnMsg(msg) {
      this.$emit('set-warn-msg', msg)
    },
  },
}
</script>

<style scoped></style>
